<template>
  <app-overlay
    class="candidate-add"
    backdrop
  >
    <modal-card
      data-test="candidate-add-modal"
      class="candidate-add__form"
      @close="handleClose"
    >
      <header>
        <p class="candidate-add__title">{{ $t(`${langPath}.title`) }}</p>
      </header>
      <candidate-form
        :submit-button-label="$t(`${langPath}.submitButton`)"
        :is-sending="isSending"
        @submit="handleSubmit"
      />
    </modal-card>
  </app-overlay>
</template>

<script>
import CandidateForm from './components/CandidateForm'
import ModalCard from '@/components/ModalCard'
import { TheNotificationCardList } from '@revelotech/everest'
import { createNamespacedHelpers } from 'vuex'
import { STAGES } from '@/store/namespaces'

const stagesHelper = createNamespacedHelpers(STAGES)

export default {
  name: 'CandidateAdd',

  components: {
    CandidateForm,
    ModalCard
  },

  data () {
    return {
      langPath: __langpath,
      isSending: false
    }
  },

  methods: {
    ...stagesHelper.mapActions(['addExternalCandidate']),
    handleClose () {
      this.$router.push({
        name: 'WorkflowBoard',
        params: {
          workflowId: this.$route.params.workflowId
        }
      })
    },
    handleSubmit (candidate) {
      this.isSending = true

      this.addExternalCandidate({
        positionId: this.$route.params.positionId,
        candidate: {
          name: candidate.name,
          email: candidate.email,
          phone: candidate.phone,
          resume: candidate.resume,
          portfolioUrl: candidate.portfolioUrl,
          linkedinUrl: candidate.linkedinUrl,
          sourceInfo: candidate.sourceInfo
        }
      }).then((_) => {
        TheNotificationCardList.createNotification({
          message: this.$t(`${this.langPath}.addSuccess`),
          modifier: 'success'
        })
        this.$gtmTrackEvent('add_candidate_confirmed')
        this.$smartlook('track', 'add_candidate_confirmed')
        this.$router.push({
          name: 'WorkflowBoard',
          params: {
            workflowId: this.$route.params.workflowId
          }
        })
      }).catch((_) => {
        TheNotificationCardList.createNotification({
          message: this.$t(`${this.langPath}.addError`),
          modifier: 'error'
        })
      }).finally((_) => {
        this.isSending = false
      })
    }
  }
}
</script>

<style lang="scss">
.candidate-add {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;

  &__form {
    height: 100vh;
    margin-bottom: 72px;
    width: 100vw;

    @include breakpoint(lg) {
      height: auto;
      left: 25vw;
      position: absolute;
      top: $base*12;
      width: 50vw;
    }
  }

  &__title {
    @extend %subtitle1;
  }
}
</style>
